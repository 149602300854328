import React from "react"
import { MDBContainer, MDBRow, MDBCol, MDBBox , MDBCarouselInner, MDBCarouselItem } from "mdbreact"
import Layout from "../components/TLCLayout"
import driveImage from "../images/exo-drive.jpg"
import protectedImage from "../images/exo-protected.jpg"

var iconStyle = {
    fontSize: '80px'
}

var calloutStyle = {
    marginTop: '2em'
}

var headerImageStyle = {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '3em',
    marginTop: '3em'
}

const WindshieldProtectionFilmServicesPage = () => (
    <Layout heroTitle="Services" heroImageUrl="./images/hero-dark.jpg">
        <MDBContainer style={{paddingTop: '100px', paddingBottom: '100px'}}>
            <MDBRow>
                <MDBCol>
                    <h3>ExoShield Nanoceramic Windhsield Protection Film</h3>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol>
                    <MDBBox display="flex" justifyContent="center">
                        <img style={headerImageStyle} src={driveImage} width="500px" />
                    </MDBBox>
                </MDBCol>
                <MDBCol>
                    <MDBBox display="flex" justifyContent="center">
                        <img style={headerImageStyle} src={protectedImage} width="500px" />
                    </MDBBox>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol>
                    <p>We're proud to offer our customers the highest quality windshield protection film on the market. ExoShield is a thin and invisible film that we install on the exterior of the windshield to reduce the risk of damage from rock chips by 6 times. Driving can be stressful enough - rest easy knowing your vehicle is protected by the best.</p>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol md="2"></MDBCol>
                <MDBCol md="8">
                    <MDBRow style={calloutStyle}>
                        <MDBCol md="2">                            
                            <MDBBox display="flex" justifyContent="center">
                                <i className="fas fa-shield-alt" style={iconStyle}></i>
                            </MDBBox>
                        </MDBCol>
                        <MDBCol md="10">
                            <h4>Stops Rock Chips</h4>
                            <p>Rock chips can happen almost anywhere, anytime. ExoShield is designed to absorb 6X more impact than a windshield alone to significantly reduce the potential for costly and annoying stone chips.</p>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow style={calloutStyle}>
                        <MDBCol md="2">                            
                            <MDBBox display="flex" justifyContent="center">
                                <i className="fas fa-binoculars" style={iconStyle}></i>
                            </MDBBox>
                        </MDBCol>
                        <MDBCol md="10">
                            <h4>Unmatched Clarity</h4>
                            <p>ExoShield is razor-thin and has the highest optical quality on the market. It won't obstruct your vision, impact sensor functionality, or interfere with your heads-up display.</p>
                        </MDBCol>
                    </MDBRow>    
                    <MDBRow style={calloutStyle}>
                        <MDBCol md="2">                            
                            <MDBBox display="flex" justifyContent="center">
                                <i className="fas fa-award" style={iconStyle}></i>
                            </MDBBox>
                        </MDBCol>
                        <MDBCol md="10">
                            <h4>Industry-Leading Warranty Coverage</h4>
                            <p>ExoShield is backed by a 2-year/30,000 mile warranty against manufacturer's defects. Unlike other windshield protection brands, ExoShield's standard manufacturer's warranty covers things like scratches and delamination.</p>
                        </MDBCol>
                    </MDBRow>                                         
                </MDBCol>
                <MDBCol md="2"></MDBCol>
            </MDBRow>
        </MDBContainer>
    </Layout>
)

export default WindshieldProtectionFilmServicesPage